import _ from "lodash";
<template>
  <div class="table row">
    <div class="col-12 m-auto">
      <BootstrapTable :columns="columns" :data="data" :options="options" />
    </div>
    <div class="col-12 m-auto">
      <highcharts :options="chartOptions"></highcharts>
    </div>
    <div class="col-12 m-auto">
      <highcharts :options="chart2Options"></highcharts>
    </div>
  </div>
</template>

<script>
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm.js';
import { DateTime } from 'luxon';
import config from '../config/config';
import HighchartsVue from 'highcharts-vue';
import { Chart } from 'highcharts-vue';
import IncidentService from '../services/incident';
import EndPoint from '../services/endpoint';
import _ from 'lodash';
import { ObjectiveKind, ObjectiveLabel } from '../types/gitlab';

export default {
  components: {
    BootstrapTable,
    highcharts: Chart,
  },
  data() {
    return {
      isBusy: false,
      customerId: 'admin',
      title: {
        UNASSIGNED_CHANGE: ObjectiveLabel.UNASSIGNED_CHANGE,
        UNRESOLVED_INCIDENT: ObjectiveLabel.UNRESOLVED_INCIDENT,
        UNSCHEDULED_CHANGE: ObjectiveLabel.UNSCHEDULED_CHANGE,
      },
      chartOptions: {
        title: {
          text: 'Solar Employment Growth by Sector, 2010-2016',
        },

        subtitle: {
          text: 'Source: thesolarfoundation.com',
        },

        yAxis: {
          title: {
            text: 'Number of Employees',
          },
        },

        xAxis: {
          accessibility: {
            rangeDescription: 'Range: 2010 to 2017',
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: 2010,
          },
        },

        series: [
          {
            name: 'Installation',
            data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
          },
          {
            name: 'Manufacturing',
            data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434],
          },
          {
            name: 'Sales & Distribution',
            data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387],
          },
          {
            name: 'Project Development',
            data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227],
          },
          {
            name: 'Other',
            data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111],
          },
        ],

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                },
              },
            },
          ],
        },
      },

      chart2Options: {
        credits: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        title: {
          text: "Répartition par type d'objectifs",
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
          },
        },
        series: [],
      },
      columns: [
        {
          title: 'Item ID',
          field: 'id',
        },
        {
          field: 'name',
          title: 'Client',
        },
        {
          field: 'createdDate',
          title: 'Date de création',
          sortable: true,
          formatter: value => {
            const formattedDate = DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
            return formattedDate;
          },
        },
      ],
      data: [],
      options: {
        search: true,
        showColumns: false,
      },
    };
  },
  async mounted() {
    this.getCustomers();
    this.getItems();
  },
  methods: {
    getCustomers() {
      this.axios
        .get(config.getUrl() + 'customers')
        .then(response => {
          this.data = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    getItems() {
      IncidentService.get(EndPoint.get(this.customerId) + EndPoint.params()).then(response => {
        this.chart2Options.series = [
          {
            name: 'Objectifs',
            colorByPoint: true,
            data: this.extractPie(response, [
              'UNASSIGNED_CHANGE',
              'UNRESOLVED_INCIDENT',
              'UNSCHEDULED_CHANGE',
              'PLANNED_CHANGE',
              'DEPLOYMENT',
            ]),
          },
        ];
      });
    },
    extractPie(data, categories) {
      return categories.map(category => {
        const element = _.filter(data, function(o) {
          return o.kind === ObjectiveKind[category];
        });
        if (element) {
          return { name: ObjectiveLabel[category], y: _.size(element) };
        } else {
          return { name: category, y: 0 };
        }
      });
    },
  },
};
</script>
